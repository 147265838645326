import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "../asstes/styles/books.scss";
import book1 from "../asstes/images/books/book-1.png";
import book2 from "../asstes/images/books/book-2.png";
import book3 from "../asstes/images/books/book-3.png";
import book4 from "../asstes/images/books/book-4.png";
import book5 from "../asstes/images/books/book-5.png";
import book6 from "../asstes/images/books/book-6.png";
import book7 from "../asstes/images/books/book-7.png";
import bhupendraDateWithIcon from "../asstes/images/events/bhupendra-date-with-icon.svg";
function BooksView() {
  const handleClick = () => { };
  return (
    <div>
      <section className="page-banner banner books-list-page banner-two">
        <Container>
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/">Information Center</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Books
              </li>
            </ol>
          </nav>
          <Row>
            <Col xs={12} md={8} lg={8} className="me-auto">
              <div className="about-banner-content">
                {/* <span className="sub-title">Bhupendra’s Events</span> */}
                <h1 className="banner-title">Books</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="books-list-page pd-common bg-dark-b">
        <Container>
          <div className="row gy-4">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="bh-event-card">
                <div className="bh-event-image">
                  <img src={book1} alt="Book" />
                  <div className="event-dt">
                    <div className="event-logo"><img src={bhupendraDateWithIcon} alt="Event Logo" /></div>
                  </div>
                </div>
                <div className="event-body-content">
                  <h2 className="event-title">Nam tristique ut vivamus aptent semper eget vitae. </h2>
                  <p className="tagline-event">In sit curabitur semper eget vitae aenean.</p>
                  <div className="event-footer">
                    <h3 className="event-price">
                      <i className="bi bi-currency-rupee"></i>899
                      <span className="event-offer">
                        <i className="bi bi-currency-rupee"></i>1299
                      </span>
                    </h3>
                  </div>
                  <div className="btn-toolbar justify-content-between books-btn-toolbar">
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white buy-now">
                      Buy Now
                    </button>
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white learn-more">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="bh-event-card">
                <div className="bh-event-image">
                  <img src={book2} alt="Event" />
                  <div className="event-dt">
                    <div className="event-logo"><img src={bhupendraDateWithIcon} alt="Event Logo" /></div>
                  </div>
                </div>
                <div className="event-body-content">
                  <h2 className="event-title">Nam tristique ut vivamus aptent semper eget vitae. </h2>
                  <p className="tagline-event">In sit curabitur semper eget vitae aenean.</p>
                  <div className="event-footer">
                    <h3 className="event-price">
                      <i className="bi bi-currency-rupee"></i>899
                      <span className="event-offer">
                        <i className="bi bi-currency-rupee"></i>1299
                      </span>
                    </h3>
                  </div>
                  <div className="btn-toolbar justify-content-between books-btn-toolbar">
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white buy-now">
                      Buy Now
                    </button>
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white learn-more">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="bh-event-card">
                <div className="bh-event-image">
                  <img src={book3} alt="Event" />
                  <div className="event-dt">
                    <div className="event-logo"><img src={bhupendraDateWithIcon} alt="Event Logo" /></div>
                  </div>
                </div>
                <div className="event-body-content">
                  <h2 className="event-title">Nam tristique ut vivamus aptent semper eget vitae. </h2>
                  <p className="tagline-event">In sit curabitur semper eget vitae aenean.</p>
                  <div className="event-footer">
                    <h3 className="event-price">
                      <i className="bi bi-currency-rupee"></i>899
                      <span className="event-offer">
                        <i className="bi bi-currency-rupee"></i>1299
                      </span>
                    </h3>
                  </div>
                  <div className="btn-toolbar justify-content-between books-btn-toolbar">
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white buy-now">
                      Buy Now
                    </button>
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white learn-more">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="bh-event-card">
                <div className="bh-event-image">
                  <img src={book4} alt="Event" />
                  <div className="event-dt">
                    <div className="event-logo"><img src={bhupendraDateWithIcon} alt="Event Logo" /></div>
                  </div>
                </div>
                <div className="event-body-content">
                  <h2 className="event-title">Nam tristique ut vivamus aptent semper eget vitae. </h2>
                  <p className="tagline-event">In sit curabitur semper eget vitae aenean.</p>
                  <div className="event-footer">
                    <h3 className="event-price">
                      <i className="bi bi-currency-rupee"></i>899
                      <span className="event-offer">
                        <i className="bi bi-currency-rupee"></i>1299
                      </span>
                    </h3>
                  </div>
                  <div className="btn-toolbar justify-content-between books-btn-toolbar">
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white buy-now">
                      Buy Now
                    </button>
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white learn-more">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="bh-event-card">
                <div className="bh-event-image">
                  <img src={book5} alt="Event" />
                  <div className="event-dt">
                    <div className="event-logo"><img src={bhupendraDateWithIcon} alt="Event Logo" /></div>
                  </div>
                </div>
                <div className="event-body-content">
                  <h2 className="event-title">Nam tristique ut vivamus aptent semper eget vitae. </h2>
                  <p className="tagline-event">In sit curabitur semper eget vitae aenean.</p>
                  <div className="event-footer">
                    <h3 className="event-price">
                      <i className="bi bi-currency-rupee"></i>899
                      <span className="event-offer">
                        <i className="bi bi-currency-rupee"></i>1299
                      </span>
                    </h3>
                  </div>
                  <div className="btn-toolbar justify-content-between books-btn-toolbar">
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white buy-now">
                      Buy Now
                    </button>
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white learn-more">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="bh-event-card">
                <div className="bh-event-image">
                  <img src={book6} alt="Event" />
                  <div className="event-dt">
                    <div className="event-logo"><img src={bhupendraDateWithIcon} alt="Event Logo" /></div>
                  </div>
                </div>
                <div className="event-body-content">
                  <h2 className="event-title">Nam tristique ut vivamus aptent semper eget vitae. </h2>
                  <p className="tagline-event">In sit curabitur semper eget vitae aenean.</p>
                  <div className="event-footer">
                    <h3 className="event-price">
                      <i className="bi bi-currency-rupee"></i>899
                      <span className="event-offer">
                        <i className="bi bi-currency-rupee"></i>1299
                      </span>
                    </h3>
                  </div>
                  <div className="btn-toolbar justify-content-between books-btn-toolbar">
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white buy-now">
                      Buy Now
                    </button>
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white learn-more">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="bh-event-card">
                <div className="bh-event-image">
                  <img src={book7} alt="Event" />
                  <div className="event-dt">
                    <div className="event-logo"><img src={bhupendraDateWithIcon} alt="Event Logo" /></div>
                  </div>
                </div>
                <div className="event-body-content">
                  <h2 className="event-title">Nam tristique ut vivamus aptent semper eget vitae. </h2>
                  <p className="tagline-event">In sit curabitur semper eget vitae aenean.</p>
                  <div className="event-footer">
                    <h3 className="event-price">
                      <i className="bi bi-currency-rupee"></i>899
                      <span className="event-offer">
                        <i className="bi bi-currency-rupee"></i>1299
                      </span>
                    </h3>
                  </div>
                  <div className="btn-toolbar justify-content-between books-btn-toolbar">
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white buy-now">
                      Buy Now
                    </button>
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white learn-more">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="bh-event-card">
                <div className="bh-event-image">
                  <img src={book7} alt="Event" />
                  <div className="event-dt">
                    <div className="event-logo"><img src={bhupendraDateWithIcon} alt="Event Logo" /></div>
                  </div>
                </div>
                <div className="event-body-content">
                  <h2 className="event-title">Nam tristique ut vivamus aptent semper eget vitae. </h2>
                  <p className="tagline-event">In sit curabitur semper eget vitae aenean.</p>
                  <div className="event-footer">
                    <h3 className="event-price">
                      <i className="bi bi-currency-rupee"></i>899
                      <span className="event-offer">
                        <i className="bi bi-currency-rupee"></i>1299
                      </span>
                    </h3>
                  </div>
                  <div className="btn-toolbar justify-content-between books-btn-toolbar">
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white buy-now">
                      Buy Now
                    </button>
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white learn-more">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="bh-event-card">
                <div className="bh-event-image">
                  <img src={book7} alt="Event" />
                  <div className="event-dt">
                    <div className="event-logo"><img src={bhupendraDateWithIcon} alt="Event Logo" /></div>
                  </div>
                </div>
                <div className="event-body-content">
                  <h2 className="event-title">Nam tristique ut vivamus aptent semper eget vitae. </h2>
                  <p className="tagline-event">In sit curabitur semper eget vitae aenean.</p>
                  <div className="event-footer">
                    <h3 className="event-price">
                      <i className="bi bi-currency-rupee"></i>899
                      <span className="event-offer">
                        <i className="bi bi-currency-rupee"></i>1299
                      </span>
                    </h3>
                  </div>
                  <div className="btn-toolbar justify-content-between books-btn-toolbar">
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white buy-now">
                      Buy Now
                    </button>
                    <button
                      type="buttton"
                      className="btn btn-default bh-btn-white learn-more">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ./col */}

            <div className="col-lg-12">
              <div className="btn-toolbar justify-content-center mt-5">
                <button
                  type="buttton"
                  className="btn btn-default btn-checkout bh-btn-dark-light"
                >
                  Load More
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="subscribe-sec pd-common subscribe-banner">
        <Container>
          <Row>
            <Col xs={12} md={8} lg={8} xl={8} className="mx-auto">
              <div className="subscribe-dt">
                <div className="title-sec mb-4">
                  <h2 className="title-heading text-white">
                    Get More of What You <br />
                    Love Subscribe Now....
                  </h2>
                </div>
                <form className="subscribe-form">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter your email address"
                  />
                  <button
                    onClick={handleClick}
                    className="btn btn-submit bh-btn-dark"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default BooksView;
