import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentStatus = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState(null);
  const [bookingResponse, setBookingResponse] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentId = queryParams.get("payment_id");
    const paymentStatus = queryParams.get("payment_status");
    const paymentRequestId = queryParams.get("payment_request_id");
    const eventBookingId = queryParams.get("event_booking_id");

    if (!paymentId || !paymentStatus || !paymentRequestId || !eventBookingId) {
      navigate("/");
    } else {
      const requestData = {
        payment_id: paymentId,
        payment_status: paymentStatus,
        payment_request_id: paymentRequestId,
        event_booking_id: parseInt(eventBookingId),
      };

      setTransactionData(requestData);

      fetch(`${process.env.REACT_APP_API_BASE_URL}api/event/confirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          setBookingResponse(data);
        })
        .catch((error) => {
          console.error("Error confirming booking:", error);
          setBookingResponse({ has_error: true, message: "Failed to confirm booking." });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [location, navigate]);

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center min-vh-100 bg-dark text-white">Loading...</div>;
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 bg-dark text-white">
      <div className="card bg-dark text-white shadow-lg w-50">
        <div className="card-body text-center">
          <h2 className={transactionData.payment_status === "Credit" ? "text-success" : "text-danger"}>
            {transactionData.payment_status === "Credit" ? "Payment Successful!" : "Payment Failed!"}
          </h2>
          <p className="card-text">Your transaction details are below:</p>

          <div className="mt-4">
            <h5 className="text-light">Transaction Details</h5>
            <table className="table table-bordered text-white">
              <tbody>
                <tr><td><strong>Payment ID:</strong></td><td>{transactionData.payment_id}</td></tr>
                <tr><td><strong>Payment Request ID:</strong></td><td>{transactionData.payment_request_id}</td></tr>
                <tr><td><strong>Status:</strong></td><td className={transactionData.payment_status === "Credit" ? "text-success" : "text-danger"}>{transactionData.payment_status}</td></tr>
                <tr><td><strong>Event Booking ID:</strong></td><td>{transactionData.event_booking_id}</td></tr>
              </tbody>
            </table>
          </div>

          {bookingResponse && !bookingResponse.has_error && (
            <div className="mt-4">
              <h5 className="text-light">Booking Confirmation Details</h5>
              <table className="table table-bordered text-white">
                <tbody>
                  <tr><td><strong>Event Name:</strong></td><td>{bookingResponse.data.name}</td></tr>
                  <tr><td><strong>Payment Status:</strong></td><td>{bookingResponse.data.payment_status}</td></tr>
                  <tr><td><strong>Amount:</strong></td><td>${bookingResponse.data.amount}</td></tr>
                  <tr><td><strong>Email:</strong></td><td>{bookingResponse.data.email}</td></tr>
                  <tr><td><strong>Phone:</strong></td><td>{bookingResponse.data.phone}</td></tr>
                  {bookingResponse.data.attendees.map((attendee, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td colSpan="2" className="text-center"><strong>Attendee {index + 1}</strong></td>
                      </tr>
                      <tr>
                        <td><strong>Name:</strong></td>
                        <td>{attendee.first_name} {attendee.last_name}</td>
                      </tr>
                      <tr>
                        <td><strong>Email:</strong></td>
                        <td>{attendee.email}</td>
                      </tr>
                      <tr>
                        <td><strong>Phone:</strong></td>
                        <td>{attendee.country_code} {attendee.phone}</td>
                      </tr>
                      <tr>
                        <td><strong>Address:</strong></td>
                        <td>{attendee.address}</td>
                      </tr>
                      <tr>
                        <td><strong>QR Code:</strong></td>
                        <td>
                          <img src={attendee.qr_code} alt="QR Code" className="w-25" />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {bookingResponse && bookingResponse.has_error && (
            <div className="alert alert-danger mt-3">{bookingResponse.message}</div>
          )}

          <a href="/" className="btn btn-light mt-3">Back to Home</a>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;