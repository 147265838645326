import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from "react-bootstrap";
// Style
import "../asstes/styles/footer.scss";
// Import icon
import twitter from "../asstes/images/icons/twitter.svg";
import instagram from "../asstes/images/icons/instagram.svg";
import linkedin from "../asstes/images/icons/linkedin.svg";
import youtoube from "../asstes/images/icons/youtube.svg";

function Footer() {
  const [showButton, setShowButton] = useState(false);
  
    useEffect(() => {
      // Function to show/hide the button based on scroll position
      const handleScroll = () => {
        if (window.scrollY > 200) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling
      });
    };
  return (
    <footer className="footer">
      <Container>
        <div className="footer-top">
          <div className="footer-logo">
          <Link to="/home" className="footer-brand">
              Bhupendra <span className="d-block">Chaudhary</span>
            </Link>
            <div className="footer-social">
              <p>Follow</p>
              <ul className="social-media-list">
                <li>
                  <Link to="">
                    <img src={twitter} alt="Twitter" />
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img src={instagram} alt="Instagram" />
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img src={linkedin} alt="Linkedin" />
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img src={youtoube} alt="Youtube"/>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-wrapper-link">
            <div className="footer-menu-list">
              <h5 className="menu-title">Quick links</h5>
              <ul className="footer-links">
                <li> <Link to="">About</Link> </li>
                <li> <Link to="">Research</Link> </li>
                <li> <Link to="">Events</Link> </li>
                <li> <Link to="">Policies</Link> </li>
              </ul>
            </div>
            {/* ./footer widget */}
            <div className="footer-menu-list">
              <h5 className="menu-title">Trainings</h5>
              <ul className="footer-links">
                <li> <Link to="">The Meta Awakening</Link> </li>
                <li> <Link to="">Chaitanya Yog Kriya</Link> </li>
                <li> <Link to="">Corporate Training</Link> </li>
                <li> <Link to="">College Training</Link> </li>
              </ul>
            </div>
            {/* ./footer widget */}
            <div className="footer-menu-list">
              <h5 className="menu-title">Initiatives</h5>
              <ul className="footer-links">
                <li> <Link to="">Soul Foundation Trust</Link> </li>
                <li> <Link to="">Induskargha Project</Link> </li>
                <li> <Link to="">Circle of Consciousness & Happiness</Link> </li> 
              </ul>
            </div>
            {/* ./footer widget */}
            <div className="footer-menu-list">
              <h5 className="menu-title">Information Centre</h5>
              <ul className="footer-links">
                <li> <Link to="">Blogs</Link> </li>
                <li> <Link to="">Podcast</Link> </li>
                <li> <Link to="">Newsletter</Link> </li>
                <li> <Link to="">Books</Link> </li>
              </ul>
            </div>
            {/* ./footer widget */}
            <div className="footer-menu-list">
              <h5 className="menu-title">Contact Us</h5>
              <ul className="footer-links">
              <li> <Link to="tel:7766666444">7766666444</Link> </li>
              <li> <Link to="mailto:enquiries@BhupendraChaudhary.in">enquiries@BhupendraChaudhary.in</Link> </li>
              </ul>
            </div>
            {/* ./footer widget */}
          </div>
        </div>
        <div className="bottom-footer">
         <p className="copyright-text"> Copyright &copy; {new Date().getFullYear()} Bhupendra Chaudhary</p>
         <p className="copyright-text brand-design"> Design And Dev by <Link className="text-white">The Meta Awakening</Link></p>
        </div>
      </Container> 
      {showButton && (
        <button onClick={scrollToTop} className='go-to-top' > <i className="bi bi-arrow-up"></i> Top </button>
      )}
    </footer>
  );
}

export default Footer;
