import React, { useState, useEffect } from "react"; 
import Tab1 from "../../asstes/images/tab-1.png";
// import Tab2 from "../../asstes/images/tab-2.png";
// import Tab3 from "../../asstes/images/tab-3.png";
import "../../asstes/styles/tab.scss";
const InitiativesTab = () => {
  const [activeTab, setActiveTab] = useState(0); // State to track the current active tab
  const [resetProgress, setResetProgress] = useState(false); // To reset progress animation

  const tabData = [
    {
      no: "01.",
      title: "Circle of Consciousness & Happiness",
      desc: "Today’s climate leaders need access to data, expert advice, and above all — carbon credit inventory at prices that fit their sustainability strategies.",
      imgSrc: Tab1,
    },
    {
      no: "02.",
      title: "Millions of tonnes at your fingertips",
      desc: "Today’s climate leaders need access to data, expert advice, and above all — carbon credit inventory at prices that fit their sustainability strategies.",
      imgSrc: Tab1,
    },
    {
      no: "03.",
      title: "Data-backed integrity",
      desc: "Today’s climate leaders need access to data, expert advice, and above all — carbon credit inventory at prices that fit their sustainability strategies.",
      imgSrc: Tab1,
    },
  ];

  // Automatically switch tabs when progress completes
  useEffect(() => {
    if (resetProgress) return; // Prevent progress reset during manual interaction

    const timer = setTimeout(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabData.length); // Move to the next tab
    }, 5000); // Match the progress bar duration (5 seconds)

    return () => clearTimeout(timer); // Cleanup timer on unmount or when activeTab changes
  }, [activeTab, resetProgress, tabData.length]);

  // Handle manual tab click
  const handleTabClick = (index) => {
    setActiveTab(index); // Activate the clicked tab
    setResetProgress(true); // Reset progress
    setTimeout(() => setResetProgress(false), 0); // Re-enable progress for the new tab
  };
  return (
    <div className="initiatives-view-tab">
      {tabData.map((tab, index) => (
        <div
          key={index}
          className={`initiatives-view-tab-item ${
            index === activeTab ? "current-tab-active" : ""
          }`}
          onClick={() => handleTabClick(index)} // Add click event to switch tabs
        >
          <div className="tab-content-top">
            <span className="tab-no">{tab.no}</span>
            <h3 className="tab-title">{tab.title}</h3>
            <p className="tab-desc">{tab.desc}</p>
          </div>
          <div className="tab-image-block">
            <img src={tab.imgSrc} alt={tab.title} />
          </div>
          {/* Progress Bar */}
          {index === activeTab && (
            <div className="card_fill-vertical">
              <div
                className="progress-bar"
                style={{
                    animation: resetProgress ? "none" : "progress-fill 5s linear",
                  }}
              ></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default InitiativesTab;
