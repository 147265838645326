import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "../asstes/styles/home.scss";
import HomeUpcomingEvent from "./sharedPages/UpcomingEvent";
import PodcastPlay from "./sharedPages/PodcastPlay";
import TestimonialsSliderView from "./sharedPages/TestimonialsSlider";
import InitiativesTab from "./sharedPages/InitiativesTab";
// Import local images
import nextEvent from "../asstes/images/next-event.png";
import migros from "../asstes/images/migros.png";
import denner from "../asstes/images/denner.png";
import helsana from "../asstes/images/helsala.png";
import feldschlosschen from "../asstes/images/feldsche.png";
import cocacolahbc from "../asstes/images/coca-cola.png";
import redbull from "../asstes/images/redbull.png";
import swiss from "../asstes/images/swiss.png";
import geberit from "../asstes/images/geberit.png";
import tal from "../asstes/images/tal.png";
import generali from "../asstes/images/general.png";
import lyrics from "../asstes/images/lyrsics.png";
import gamed from "../asstes/images/gamed.png";
// import podcast1 from "../asstes/images/podcast/podcast-1.png";
// import podcast2 from "../asstes/images/podcast/podcast-2.png";
// import podcast3 from "../asstes/images/podcast/podcast-3.png";
// import podcast4 from "../asstes/images/podcast/podcast-4.png";
// import podcast5 from "../asstes/images/podcast/podcast-5.png";
// import podcastPlayView from "../asstes/images/podcast/podcast-play.png";
import ratingUserProfile from "../asstes/images/sarah-johan.png";
// import BhupendraProfileAboutPic from "../asstes/images/bhupendra-about.png";
import AboutPhotoShoot from "../asstes/images/about-photoshoot.jpg";
import moment from "moment";

// -------------------------------------------

function Home() {

  const navigate = useNavigate();

  const [podcasts, setPodcasts] = useState([]);
  const [blog, setBlog] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  // Podcast API 
  const fetchPodcastAPI = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/podcasts`, {
        method: "GET"
      });
      if (response.status === 200) {
        const res = await response.json();

        if (res.data && res.data.podcasts) {
          setPodcasts(res.data.podcasts);
        } else {
          console.error("Unexpected API response structure:", res);
        }
      }
      else if (response.status === 401) {
        navigate("/sign-in")
      }
      else {
        console.error("Unexpected API response structure:");
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Blog API 
  const fetchBlogAPI = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/blogs`, {
        method: "GET"
      });
      if (response.status === 200) {
        const res = await response.json();

        if (res.data && res.data.Blogs) {
          const sortedBlogs = res.data.Blogs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setBlog(sortedBlogs.slice(0, 2));
        } else {
          console.error("Unexpected API response structure:", res);
        }
      }
      else if (response.status === 401) {
        navigate("/sign-in")
      }
      else {
        console.error("Unexpected API response structure");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const featuredPodcast = podcasts?.find((podcast) => podcast.rank === 1);
  const otherPodcasts = podcasts
    ?.filter((podcast) => podcast.rank !== 1)
    .sort((a, b) => a.rank - b.rank);

  const handleClickBlog = (id) => {
    navigate(`/blog-detail/${id}`)
  }

  useEffect(() => {
    fetchPodcastAPI();
    fetchBlogAPI();
    AOS.init({
      duration: 1200, // Animation duration (ms)
      easing: "ease-in-out", // Smooth animation effect
      once: true
    }); // Initialize AOS
    // eslint-disable-next-line
  }, []);

  // Video Click Function 
  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const logos = [
    { src: migros, alt: "migros" },
    { src: denner, alt: "denner" },
    { src: helsana, alt: "helsana" },
    { src: feldschlosschen, alt: "feldschlosschen" },
    { src: cocacolahbc, alt: "Coca Cola HBC" },
    { src: redbull, alt: "Red Bull" },
    { src: swiss, alt: "swiss" },
    { src: geberit, alt: "geberit" },
    { src: tal, alt: "tal" },
    { src: generali, alt: "generali" },
    { src: lyrics, alt: "lyrics" },
    { src: gamed, alt: "gamed" },
  ];

  return (
    <div>
      <section className="bh-home-banner banner">
        <Container>
          <Row className="align-items-end">
            <Col xs={12} md={8} lg={9}>
              <div className="home-banner-content banner-desc" data-aos="fade-up">
                <h1 className="bh-title">
                  Bhupendra <span className="text-grey">Chaudhary</span>
                </h1>
                <h2 className="banner-title">
                  Evolving <span className="text-orange">Humanity</span> with the Science of Spirituality{" "}
                </h2>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <div className="event-card" data-aos="zoom-in">
                <Link to="" className="event-dt">
                  <div className="event-image" onClick={handleVideoClick}>
                    <video
                      ref={videoRef}
                      src="/hero-section-video.mp4"
                      className="img-fluid"
                      controls
                      autoPlay
                    />
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* About Bhupendra Section Start Here */}
      <section className="bh-about-sec pd-common bg-dark-b">
        <Container>
          {/* Title Section */}
          <div className="about-title title-sec" data-aos="fade-up">
            <h2 className="title-heading text-white">
              <span className="bh-tag-heading">
                Bhupendra<small>Chaudhary</small>
              </span>
              <i className="bi bi-quote"></i>Bhupendra Chaudhary reminds us that Infinite Consciousness is not a pursuit for a few, but a gift available to every human being—waiting to be realized, awakened, and lived through purposeful awareness.
            </h2>
          </div>

          {/* About Content */}
          <Row className="gy-3">
            {/* Left Section */}
            <Col xs={12} md={7} lg={7} className="mx-auto" data-aos="fade-right">
              <div className="hm-about-content">
                <div className="rating-view">
                  <ul>
                    <li>
                      Whether you're seeking clarity, deeper relationships, or the ultimate truth of existence, Bhupendra Chaudhary invites you to walk the path of the System of Ultimate Life (SOUL)—where Infinite Consciousness awakens, guiding you to live with purpose, connection, success and inner harmony.
                    </li>
                    <li>
                      <div className="media-rating">
                        <div className="user-pic">
                          <img
                            src={ratingUserProfile}
                            alt="Sarah & John, Newlyweds"
                          />
                        </div>
                        <div className="review-content">
                          <span className="review-value">
                            5.0 / 5
                            <small className="review-start">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                            </small>
                          </span>
                          <p>
                            Bhupendra Chaudhary creates a community that brings together like-minded individuals to co-create their journeys in a very welcoming and safe space.
                          </p>
                          <span className="review-name">
                            Sarah & John, Newlyweds
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="profile-about" data-aos="zoom-in">
                  <img
                    src={nextEvent}
                    className="img-fluid"
                    alt="Next Event"
                  />
                </div>
              </div >
            </Col >
            <Col xs={12} md={3} lg={3} className="mx-auto" data-aos="fade-left">
              <Link to={"https://www.usatoday.com/story/special/contributor-content/2025/03/21/awakening-genius-in-corporate-culture-a-new-approach-to-conscious-leadership-with-bhupendra-chaudhar/82597928007/"} target="_blank" className="text-black">
                <div className="bh-ab-photoshoot">
                  <div className="about-moment-card">
                    <div className="photo-shoot video-btn">
                      <img src={AboutPhotoShoot} alt="AboutPhotoShoot" />
                      {/* <button className="play-video-btn">
                      <i className="bi bi-play-fill"></i>
                    </button> */}
                    </div>
                    <div className="about-moment-content" data-aos="flip-up">
                      <h3 className="moment-title">
                        Awakening Genius in Corporate Culture
                      </h3>
                      <div className="moment-desc">
                        <p>
                          A New Approach to Conscious Leadership with Bhupendra Chaudhary
                        </p>
                        <div className="go-link">
                          <button className="arrow-up-btn icon-bt-dark">
                            <i className="bi bi-arrow-up-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-toolbar ms-3">
                    <Link to={"/about"} className="text-black">
                      <button
                        type="buttton"
                        className="btn btn-default btn-checkout bh-btn-white"
                        data-aos="fade-up"
                      >
                        Learn More
                      </button>
                    </Link>
                  </div>
                </div>
              </Link>
            </Col>
          </Row >
        </Container >
      </section >
      {/* Together we are achieving great things Section Start */}
      < section className="bh-together pd-common bg-dark-b" >
        <Container>
          <Row>
            <Col xs={12} md={8} lg={6} className="mx-auto">
              <div className="title-sec" data-aos="fade-up">
                <h2 className="title-heading text-white">
                  Together we are achieving great things
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="g-2 item-hover">
            {logos.map((logo, index) => (
              <Col
                key={index}
                xs={4}
                md={3}
                lg={3}
                xl={2}
                data-aos="fade-up"
                data-aos-delay={index * 100} // Adds a slight delay to each column
              >
                <div className="services-box">
                  <img src={logo.src} alt={logo.alt} />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section >
      {/* The Bhupendra Upcoming events Section Start */}
      < section className="bh-upcomming-event pd-common bg-dark-b" >
        <Container>
          <Row>
            <Col xs={12} md={8} lg={6} className="p-relative">
              <div className="title-sec">
                <h2 className="title-heading text-white title-left">
                  The Bhupendra Upcoming events
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
        <HomeUpcomingEvent />
      </section >
      {/* The Meta Awakening Section Start */}
      < section className="bh-meta-awakening pd-common" >
        <Container>
          <div className="meta-awakening-desc">
            <p>
              Orci libero euismod fames viverra vel ullamcorper mi. Ad mattis
              potenti commodo lobortis dui vestibulum litora consequat. Etiam
              blandit.
            </p>
          </div>
        </Container>
        <div className="title-sec">
          <h2 className="title-heading">The Meta Awakening</h2>
        </div>
      </section >
      {/* Testimonials Section Start */}
      < section className="bh-testimonials-sec pd-common bg-white" >
        <TestimonialsSliderView />
      </section >
      {/* Our Initiatives Some Text Section Start */}
      < section className="bh-initiatives pd-common bg-dark-b" >
        <Container>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <div className="title-with-desc">
                <div className="title-sec">
                  <h2 className="title-heading text-white title-left">
                    Our Initiatives Some Text
                  </h2>
                  <p>
                    Today’s climate leaders need access to data, expert advice,
                    and above all — carbon credit inventory at prices that fit
                    their sustainability strategies.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <div className="our-initiatives-view">
                <InitiativesTab />
              </div>
            </Col>
          </Row>
        </Container>
      </section >
      {/* Testimonials Section Start */}
      < section className="bh-podcast-sec pd-common bg-white" >
        <Container>
          <div className="title-sec">
            <h2 className="title-heading text-black">The Bhupendra Podcast</h2>
          </div>
          <Row className="gy-3">
            <Col xs={12} md={12} lg={6} xl={5} className="mx-auto">
              {
                featuredPodcast && (
                  <div className="podcast-play-view">
                    <div className="podcast-play-img">
                      <img src={`${process.env.REACT_APP_API_BASE_URL}${featuredPodcast?.thumbnail}`} alt="podcastPlayView" />
                    </div>
                    <div className="current-play-dt">
                      <ul>
                        <li>{featuredPodcast?.category}</li>
                        <li>
                          <span className="pd-date">{moment(featuredPodcast?.date).format("MMM DD, YYYY")}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="track-play">
                      <h3 className="track-title">
                        {featuredPodcast?.title}
                      </h3>
                      <PodcastPlay link={featuredPodcast?.link} />
                    </div>
                  </div>
                )
              }
            </Col>
            <Col xs={12} md={12} lg={6} xl={6} className="mx-auto">
              <div className="podcast-play-list">
                {otherPodcasts.length && otherPodcasts.slice(0, 5).map((ele, index) => {
                  return (
                    <div className="podcast-itme-list" key={index}>
                      <div className="podcast-media">
                        <div className="media-block">
                          <img src={`${process.env.REACT_APP_API_BASE_URL}${ele?.thumbnail}`} alt="podcast-1" />
                        </div>
                        <div className="media-desc current-play-dt">
                          <ul>
                            <li>{ele?.category}</li>
                            <li>
                              <span className="pd-date">{moment(ele?.date).format("MMM DD, YYYY")}</span>
                            </li>
                          </ul>
                          <h2 className="media-title">{ele?.title}</h2>
                        </div>
                      </div>
                      <div className="podcast-play-btn">
                        <Link to={ele?.link} target="_blank">
                          <button className="listen-btn">
                            <i className="bi bi-play-fill"></i>Listen
                          </button>
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </section >
      {/* My Blog Section Start */}
      < section className="bh-blog-sec pd-common bg-dark-b" >
        <Container>
          <Row>
            {/* Blog Title Section */}
            <Col xs={12} md={4} lg={4} xl={4} data-aos="fade-right">
              <div className="blog-title title-with-desc">
                <div className="title-sec">
                  <h2 className="title-heading text-white title-left">
                    My blog
                  </h2>
                  <p>
                    Curious about what goes into a perfect photoshoot? Check out
                    my latest tips and behind-the-scenes stories.
                  </p>
                </div>
                <div className="btn-toolbar-title">
                  <Link to={"/blogs"}>
                    <button className="btn bh-btn-white view-all">
                      View All
                    </button>
                  </Link>
                </div>
              </div>
            </Col>

            {/* Blog Cards Section */}
            <Col xs={12} md={8} lg={8} xl={8}>
              <Row>
                {blog.length > 0 &&
                  blog.map((blog, index) => (
                    <Col
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                      key={index}
                      data-aos="fade-up"
                      data-aos-delay={index * 150} // Staggered animation effect
                    >
                      <div
                        className="blog-card blog-1 bg-blog"
                        style={{
                          background: `url(${process.env.REACT_APP_API_BASE_URL}/${blog?.image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        onClick={() => handleClickBlog(blog?.id)}
                      >
                        <div className="blog-header">
                          <span className="post-date">
                            {moment(blog?.created_at).format("MMM DD, YYYY")}
                          </span>
                          <span className="post-arrow">
                            <i className="bi bi-arrow-up-right"></i>
                          </span>
                        </div>
                        <h3 className="blog-title">{blog?.title}</h3>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Home;